import {get, store, update, destroy, create, edit, show} from "@/services/resources";

    export const state = {
        editData : undefined,
        data : undefined,
        page:undefined,
        search:undefined,
        loadingPage:false,
        per_page:10,
    };
    export const mutations =  {
        SET_LOADING_PAGE(state,payload){
            state.loadingPage = payload
        },
        SET_DATA(state,payload){
            state.data = payload
        },
        SET_EDIT_DATA(state,payload){
            state.editData = payload
        },
    };
    export const actions =  {
        get({commit},url){
            if (!url.without_commit){
                commit('SET_DATA', undefined)
            }

            return new Promise((resolve,reject)=>{
                get(url).then((response)=>{
                    if (!url.without_commit){
                        commit('SET_DATA',response.data)
                        commit('SET_LOADING_PAGE',false)
                    }
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        create({commit},url){
            return new Promise((resolve,reject)=>{
                create(url).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        createWithoutCommit(_,url){
            return new Promise((resolve,reject)=>{
                create(url).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        show({commit},data){
            if (!data.without_commit){
                commit('SET_DATA', undefined)
            }
            return new Promise((resolve,reject)=>{
                show(data.resource, data.id).then((response)=>{
                   if (!data.without_commit){
                       commit('SET_DATA',response.data)
                   }
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        edit({commit},data){
            return new Promise((resolve,reject)=>{
                edit(data.url,data.id).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        store({commit},data){
            return new Promise((resolve,reject)=>{
                store(data.resource,data.data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        update({commit},data){
            return new Promise((resolve,reject)=>{
                update(data.resource,data.data,data.id).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        destroy({commit},data){
            return new Promise((resolve,reject)=>{
                destroy(data.resource,data.id).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },   // eslint-disable-next-line no-unused-vars
        resetData({commit}){
            commit('SET_EDIT_DATA',undefined)
            commit('SET_DATA',undefined)
        },

    };
    export const getters =  {

    };

